import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import Logo from "./images/logo-flightcake-black.png";

import CreatePost from "./components/CreatePost";
import ViewSinglePost from "./components/ViewSinglePost";
//import ViewAllPosts from "./components/ViewAllPosts";

import { Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <div className="background pb-5">
      <div className="py-5 d-flex justify-content-center">
        <div style={{ width: "fit-content" }}>
          <img src={Logo} alt="logo" />
          <div className="">
            <Link className="text-center mt-2 mx-2" to={"/"}>
              Create Post
            </Link>
            <Link className="text-center mt-2 mx-2" to={"/post"}>
              View All Posts
            </Link>
          </div>
        </div>
      </div>
      <Switch>
        <Route exact path="/" component={CreatePost} />
        <Route path="/post" component={ViewSinglePost} />
      </Switch>
    </div>
  );
}

//<Switch></Switch>
//<Route exact path="/" component={PostList}/>
//<Route path="/post-list" component={PostList}/>
//<Route path="/edit-post/:id" component={EditPost}/>

export default App;
