import React, { useState } from "react";
import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";

const CreatePost = () => {
  const [formData, setFormData] = useState({});
  const [bannerBearImage, setBannerBearImage] = useState("");
  const [premiumList, setPremiumList] = useState(false);

  /*
  const postToInstagram = () => {
    axios
      .post("https://hooks.zapier.com/hooks/catch/10698327/b6x1ytg", {
        body: {
          content: formData.content,
          image_url: bannerBearImage,
        },
      })
      .catch((err) => {
        console.log(err);
      });
  };
  */

  const runBannerBear = async (imageURL) => {
    var data = {
      template: "20KwqnDE7RQZl17dYG",
      modifications: [
        {
          name: "dates",
          text: formData.postDate,
        },
        {
          name: "cheap_price",
          text: formData.postCheapPrice,
        },
        {
          name: "normal_price",
          text: formData.postNormalPrice,
        },
        {
          name: "destination_code",
          text: formData.postDestinationCode,
        },
        {
          name: "destination_country",
          text: formData.postDestinationCountry,
        },
        {
          name: "destination_city",
          text: formData.postDestinationCity,
        },
        {
          name: "background_image",
          image_url: imageURL,
        },
      ],
    };
    const response = await fetch("https://sync.api.bannerbear.com/v2/images", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer w0FowM6nyQR4gvHX5dchSgtt`,
      },
    });

    const answer = await response.json();
    console.log(answer);

    setBannerBearImage(answer.image_url_jpg);
  };

  //run on HOST=0.0.0.0 npm run start 192.168.1.194:3000
  const imageUpload = (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    fetch("https://api.imgur.com/3/image", {
      method: "post",
      headers: {
        Authorization: "Client-ID 85263101c5abc8f",
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => runBannerBear(data.data.link));
  };

  const handleInput = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      premiumList: premiumList,
    }));
    console.log(formData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const postObject = {
      title: formData.title,
      content: formData.content,
      postDestinationCode: formData.postDestinationCode,
      postDestinationCity: formData.postDestinationCity,
      postDestinationCountry: formData.postDestinationCountry,
      postCheapPrice: formData.postCheapPrice,
      postNormalPrice: formData.postNormalPrice,
      postPremium: formData.premiumList,
      postDealLink: formData.postDealLink,
      postDate: formData.postDate,
      bannerBearImage: bannerBearImage,
    };

    axios
      .post("https://flightcakekc.herokuapp.com/posts/create-post", postObject)
      .then((res) => console.log(res));
  };

  return (
    <Container className="p-4" style={{ backgroundColor: "white" }}>
      <Form>
        <Form.Group className="mb-3" controlId="postTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            name="title"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="postContent">
          <Form.Label>Content</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter content"
            rows={3}
            name="content"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="postDestinationCode">
          <Form.Label>Destination IATA Code</Form.Label>
          <Form.Control
            name="postDestinationCode"
            type="text"
            placeholder="Enter IATA Code"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="postDestinationCity">
          <Form.Label>Destination City</Form.Label>
          <Form.Control
            name="postDestinationCity"
            type="text"
            placeholder="Enter Destination City"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="postDestinationCountry">
          <Form.Label>Destination Country</Form.Label>
          <Form.Control
            name="postDestinationCountry"
            type="text"
            placeholder="Enter Destination Country"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="postCheapPrice">
          <Form.Label>Cheap Price</Form.Label>
          <Form.Control
            name="postCheapPrice"
            type="text"
            placeholder="Enter Cheap Price"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="postNormalPrice">
          <Form.Label>Normal Price</Form.Label>
          <Form.Control
            name="postNormalPrice"
            type="text"
            placeholder="Enter Normal Price"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="postDate">
          <Form.Label>Date</Form.Label>
          <Form.Control
            name="postDate"
            type="text"
            placeholder="Enter Date"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="postPremium">
          <Form.Label>Premium</Form.Label>
          <Form.Check
            label="True"
            name="postPremium"
            type="radio"
            onChange={(e) => setPremiumList(true)}
          />
          <Form.Check
            label="False"
            name="postPremium"
            type="radio"
            onChange={(e) => setPremiumList(false)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="postDealLink">
          <Form.Label>Deal Link</Form.Label>
          <Form.Control
            name="postDealLink"
            type="text"
            placeholder="Enter Deal Link"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group controlId="postBackgroundImage" className="mb-3">
          <Form.Label>Background Image</Form.Label>
          <Form.Control onChange={(e) => imageUpload(e)} type="file" />
        </Form.Group>

        {bannerBearImage === "" ? (
          <Button variant="primary" type="submit" disabled>
            Submit
          </Button>
        ) : (
          <div>
            <Alert variant="success">
              <p>BannerBear image has been successfully created</p>
            </Alert>

            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        )}
      </Form>
    </Container>
  );
};

export default CreatePost;
